// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__NMnDi {
  position: relative;
  z-index: 10;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.styles_track__cXG3l {
  width: 100%;
  height: 2px;
  background: var(--accent);
  position: relative;
}
.styles_track_marker__M4XsR {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--accent);
  transform: translateY(-50%);
}
.styles_track_marker__M4XsR.styles_start__4lFJi {
  left: 0;
}
.styles_track_marker__M4XsR.styles_middle__zQlYx {
  left: 50%;
  transform: translate(-50%, -50%);
}
.styles_track_marker__M4XsR.styles_end__wwwxX {
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/ui/MatchTrack/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,WAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,2BAAA;AACR;AACQ;EACI,OAAA;AACZ;AAEQ;EACI,SAAA;EACA,gCAAA;AAAZ;AAGQ;EACI,QAAA;AADZ","sourcesContent":[".wrapper {\n    position: relative;\n    z-index: 10;\n    height: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.track {\n    width: 100%;\n    height: 2px;\n    background: var(--accent);\n    position: relative;\n\n    &_marker {\n        position: absolute;\n        top: 50%;\n        width: 8px;\n        height: 8px;\n        border-radius: 50%;\n        background: var(--accent);\n        transform: translateY(-50%);\n\n        &.start {\n            left: 0;\n        }\n\n        &.middle {\n            left: 50%;\n            transform: translate(-50%, -50%);\n        }\n\n        &.end {\n            right: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__NMnDi`,
	"track": `styles_track__cXG3l`,
	"track_marker": `styles_track_marker__M4XsR`,
	"start": `styles_start__4lFJi`,
	"middle": `styles_middle__zQlYx`,
	"end": `styles_end__wwwxX`
};
export default ___CSS_LOADER_EXPORT___;
