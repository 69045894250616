// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_playerImg__roYsF {
  height: 30px; /* Set the image height to 30px */
  width: auto; /* Adjust width automatically to maintain aspect ratio */
  /* Or specify a fixed width if you prefer */
  /* width: 20px; */
}

.styles_name__rgANd {
  font-size: 2rem;
}

.styles_karte__7k9Bn {
  display: block;
  width: 12px;
  height: 17px;
  border-radius: 2px;
}
.styles_karte_wrapper__GlgBU {
  transform: scaleX(-1);
  display: flex;
}
.styles_karte__7k9Bn.styles_red__Jot6X {
  background: #E0162E;
  border: 2px solid #910C0C;
}
.styles_karte__7k9Bn.styles_yellow__7n\\+dr {
  background: #F9C700;
  border: 2px solid #A37F00;
  position: relative;
}
.styles_karte__7k9Bn.styles_yellow__7n\\+dr.styles_ltr__IMRud:not(:first-child) {
  margin-left: -6px;
}
.styles_karte__7k9Bn.styles_yellow__7n\\+dr.styles_rtl__Ho2QO:not(:first-child) {
  margin-right: -6px;
}`, "",{"version":3,"sources":["webpack://./src/components/Row/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA,EAAA,iCAAA;EACA,WAAA,EAAA,wDAAA;EACA,2CAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EACI,qBAAA;EACA,aAAA;AACR;AAEI;EACI,mBAAA;EACA,yBAAA;AAAR;AAGI;EACI,mBAAA;EACA,yBAAA;EACA,kBAAA;AADR;AAGQ;EACI,iBAAA;AADZ;AAIQ;EACI,kBAAA;AAFZ","sourcesContent":[".playerImg {\n    height: 30px; /* Set the image height to 30px */\n    width: auto; /* Adjust width automatically to maintain aspect ratio */\n    /* Or specify a fixed width if you prefer */\n    /* width: 20px; */\n}\n\n.name {\n    font-size: 2rem;\n}\n\n.karte {\n    display: block;\n    width: 12px;\n    height: 17px;\n    border-radius: 2px;\n\n    &_wrapper {\n        transform: scaleX(-1);\n        display: flex;\n    }\n\n    &.red {\n        background: #E0162E;\n        border: 2px solid #910C0C;\n    }\n\n    &.yellow {\n        background: #F9C700;\n        border: 2px solid #A37F00;\n        position: relative;\n\n        &.ltr:not(:first-child) {\n            margin-left: -6px;\n        }\n\n        &.rtl:not(:first-child) {\n            margin-right: -6px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playerImg": `styles_playerImg__roYsF`,
	"name": `styles_name__rgANd`,
	"karte": `styles_karte__7k9Bn`,
	"karte_wrapper": `styles_karte_wrapper__GlgBU`,
	"red": `styles_red__Jot6X`,
	"yellow": `styles_yellow__7n+dr`,
	"ltr": `styles_ltr__IMRud`,
	"rtl": `styles_rtl__Ho2QO`
};
export default ___CSS_LOADER_EXPORT___;
