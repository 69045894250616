// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_switch__jqp4N input {
  display: none;
}
.style_switch_slider__nvq2I {
  height: 14px;
  width: 36px;
  background-color: var(--border);
  border-radius: 20px;
  position: relative;
  display: block;
}
.style_switch_slider__nvq2I:before {
  content: "";
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.06);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--widget-light);
  position: absolute;
  top: 50%;
  left: 0;
  transition: left var(--transition), background-color var(--transition);
  transform: translateY(-50%);
}
input:checked + .style_switch_slider__nvq2I:before {
  left: 16px;
}
.style_switch__jqp4N.style_light__Yj82o input:checked + .style_switch_slider__nvq2I:before {
  background-color: var(--grass);
}
.style_switch__jqp4N.style_dark__2l1ar input:checked + .style_switch_slider__nvq2I:before {
  background-color: var(--accent);
}`, "",{"version":3,"sources":["webpack://./src/ui/Switch/style.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAGI;EACI,YAAA;EACA,WAAA;EACA,+BAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AADR;AAGQ;EACI,WAAA;EACA,yCAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qCAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;EACA,sEAAA;EACA,2BAAA;AADZ;AAKI;EACI,UAAA;AAHR;AAMI;EACI,8BAAA;AAJR;AAOI;EACI,+BAAA;AALR","sourcesContent":[".switch {\n    input {\n        display: none;\n    }\n\n    &_slider {\n        height: 14px;\n        width: 36px;\n        background-color: var(--border);\n        border-radius: 20px;\n        position: relative;\n        display: block;\n\n        &:before {\n            content: '';\n            box-shadow: 0 3px 1px rgba(0, 0, 0, 0.06);\n            height: 20px;\n            width: 20px;\n            border-radius: 50%;\n            background-color: var(--widget-light);\n            position: absolute;\n            top: 50%;\n            left: 0;\n            transition: left var(--transition), background-color var(--transition);\n            transform: translateY(-50%);\n        }\n    }\n\n    input:checked + &_slider:before {\n        left: 16px;\n    }\n\n    &.light input:checked + &_slider:before {\n        background-color: var(--grass);\n    }\n\n    &.dark input:checked + &_slider:before {\n        background-color: var(--accent);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `style_switch__jqp4N`,
	"switch_slider": `style_switch_slider__nvq2I`,
	"light": `style_light__Yj82o`,
	"dark": `style_dark__2l1ar`
};
export default ___CSS_LOADER_EXPORT___;
