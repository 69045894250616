// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Finanzen.module.css */
.Finanzen_pageHeader__o\\+9C1 {
    margin-bottom: 20px;
}

.Finanzen_grid__Cem5G {
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Finanzen.module.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["/* Finanzen.module.css */\n.pageHeader {\n    margin-bottom: 20px;\n}\n\n.grid {\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `Finanzen_pageHeader__o+9C1`,
	"grid": `Finanzen_grid__Cem5G`
};
export default ___CSS_LOADER_EXPORT___;
