// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__I6QYS {
  color: var(--text);
  background: var(--widget);
  padding: 0 15px;
  border-top: 2px solid var(--border);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.styles_button__B452p {
  font-size: 18px;
  transition: color var(--transition);
  color: var(--highlight);
}
.styles_button__B452p:hover {
  color: var(--header);
}

.styles_menu_item__uqAEu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styles_menu_item__uqAEu:not(:last-child) {
  border-bottom: 1px solid var(--border);
  padding-bottom: 8px;
}
.styles_menu_item__uqAEu:last-child {
  margin-top: -8px;
}`, "",{"version":3,"sources":["webpack://./src/layout/BottomNav/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,mCAAA;EACA,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;AACJ;;AAEA;EACI,eAAA;EACA,mCAAA;EACA,uBAAA;AACJ;AACI;EACI,oBAAA;AACR;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAEI;EACI,sCAAA;EACA,mBAAA;AAAR;AAGI;EACI,gBAAA;AADR","sourcesContent":[".container {\n    color: var(--text);\n    background: var(--widget);\n    padding: 0 15px;\n    border-top: 2px solid var(--border);\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 1000;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 20px;\n}\n\n.button {\n    font-size: 18px;\n    transition: color var(--transition);\n    color: var(--highlight);\n\n    &:hover {\n        color: var(--header);\n    }\n}\n\n.menu_item {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    &:not(:last-child) {\n        border-bottom: 1px solid var(--border);\n        padding-bottom: 8px;\n    }\n\n    &:last-child {\n        margin-top: -8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__I6QYS`,
	"button": `styles_button__B452p`,
	"menu_item": `styles_menu_item__uqAEu`
};
export default ___CSS_LOADER_EXPORT___;
