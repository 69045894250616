// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_item__LDED4 {
  padding: 16px var(--card-padding);
  border-bottom: 1px solid var(--border);
}
.styles_item__LDED4:first-child {
  padding-top: 0;
}
.styles_item__LDED4:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.styles_popup__QnZ-g {
  max-width: 500px;
  padding: 0;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ShoppingCart/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,sCAAA;AACJ;AACI;EACI,cAAA;AACR;AAEI;EACI,iBAAA;EACA,mBAAA;AAAR;;AAIA;EACI,gBAAA;EACA,UAAA;EACA,gBAAA;AADJ","sourcesContent":[".item {\n    padding: 16px var(--card-padding);\n    border-bottom: 1px solid var(--border);\n\n    &:first-child {\n        padding-top: 0;\n    }\n\n    &:last-child {\n        padding-bottom: 0;\n        border-bottom: none;\n    }\n}\n\n.popup {\n    max-width: 500px;\n    padding: 0;\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `styles_item__LDED4`,
	"popup": `styles_popup__QnZ-g`
};
export default ___CSS_LOADER_EXPORT___;
