// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kader_kaderGrid__4je0a {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 4 columns */
  gap: 30px;
  padding: 16px;
  width: 100%; /* Ensures grid takes full width of its container */
}
@media (max-width: 1200px) {
  .Kader_kaderGrid__4je0a {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for large tablets or small desktops */
  }
}
@media (max-width: 900px) {
  .Kader_kaderGrid__4je0a {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
  }
}
@media (max-width: 600px) {
  .Kader_kaderGrid__4je0a {
    grid-template-columns: 1fr; /* 1 column for mobile devices */
  }
}

.Kader_card__bM3Yc {
  width: 100%; /* Each card takes the full width of its grid column */
  height: auto; /* Allow height to adjust based on content */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Kader_card__bM3Yc {
  width: 100%; /* Each card takes the full width of its grid column */
  height: 460px; /* Fixed height for the player info card */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/widgets/PlayerKaderInfo/Kader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA,EAAA,uBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA,EAAA,mDAAA;AACF;AACE;EAPF;IAQI,qCAAA,EAAA,kDAAA;EAEF;AACF;AAAE;EAXF;IAYI,qCAAA,EAAA,0BAAA;EAGF;AACF;AADE;EAfF;IAgBI,0BAAA,EAAA,gCAAA;EAIF;AACF;;AADA;EACE,WAAA,EAAA,sDAAA;EACA,YAAA,EAAA,4CAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAIF;;AADA;EACE,WAAA,EAAA,sDAAA;EACA,aAAA,EAAA,0CAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAIF","sourcesContent":[".kaderGrid {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr); /* Default: 4 columns */\n  gap: 30px;\n  padding: 16px;\n  width: 100%; /* Ensures grid takes full width of its container */\n\n  @media (max-width: 1200px) {\n    grid-template-columns: repeat(3, 1fr); /* 3 columns for large tablets or small desktops */\n  }\n\n  @media (max-width: 900px) {\n    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */\n  }\n\n  @media (max-width: 600px) {\n    grid-template-columns: 1fr; /* 1 column for mobile devices */\n  }\n}\n\n.card {\n  width: 100%; /* Each card takes the full width of its grid column */\n  height: auto; /* Allow height to adjust based on content */\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.card {\n  width: 100%; /* Each card takes the full width of its grid column */\n  height: 460px; /* Fixed height for the player info card */\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kaderGrid": `Kader_kaderGrid__4je0a`,
	"card": `Kader_card__bM3Yc`
};
export default ___CSS_LOADER_EXPORT___;
