// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_thumbs__h6S2A {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  background: rgba(40, 40, 40, 0.95);
  z-index: 3;
  border-top: 1px solid #1B1F22;
}
.styles_thumbs_slide__Yz-ci {
  padding: 30px 30px 34px;
}
@media screen and (min-width: 768px) {
  .styles_thumbs_slide__Yz-ci {
    border-left: 1px solid #1B1F22;
  }
}

.styles_live__XRoj4 {
  top: 30px;
  left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/LiveMatches/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,OAAA;EACA,WAAA;EACA,kCAAA;EACA,UAAA;EACA,6BAAA;AACJ;AACI;EACI,uBAAA;AACR;AACQ;EAHJ;IAIQ,8BAAA;EAEV;AACF;;AAEA;EACI,SAAA;EACA,UAAA;AACJ","sourcesContent":[".thumbs {\n    position: absolute;\n    bottom: 4px;\n    left: 0;\n    width: 100%;\n    background: rgba(40, 40, 40, 0.95);\n    z-index: 3;\n    border-top: 1px solid #1B1F22;\n\n    &_slide {\n        padding: 30px 30px 34px;\n\n        @media screen and (min-width: 768px) {\n            border-left: 1px solid #1B1F22;\n        }\n    }\n}\n\n.live {\n    top: 30px;\n    left: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbs": `styles_thumbs__h6S2A`,
	"thumbs_slide": `styles_thumbs_slide__Yz-ci`,
	"live": `styles_live__XRoj4`
};
export default ___CSS_LOADER_EXPORT___;
