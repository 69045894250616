// components
import PageHeader from '@layout/PageHeader';
const Blank = () => {
    return (
        <>
            <PageHeader title="Blank Page" />
        </>
    )
}

export default Blank