// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__QDX5V {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.styles_media__IuvfQ {
  width: 160px;
  height: 160px;
  align-self: flex-end;
  object-fit: contain;
}
@media screen and (min-width: 414px) {
  .styles_media__IuvfQ {
    width: 190px;
    height: 190px;
    object-fit: contain;
  }
}

.styles_main__0Rguz {
  height: auto;
  max-width: calc(100% - 184px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  object-fit: contain;
}
.styles_main__0Rguz.styles_ltr__jicg2 {
  padding: 30px 0 30px 30px;
}
.styles_main__0Rguz.styles_rtl__w8-yC {
  padding: 30px 30px 30px 0;
}
.styles_main_title__UMmbd {
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/VetragButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,oBAAA;EACA,mBAAA;AACJ;AAEI;EAPJ;IAQQ,YAAA;IACA,aAAA;IACA,mBAAA;EACN;AACF;;AAEA;EACI,YAAA;EACA,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAEI;EACI,yBAAA;AAAR;AAGI;EACI,yBAAA;AADR;AAII;EACI,gBAAA;AAFR","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: space-between;\n    gap: 24px;\n}\n\n.media {\n    width: 160px;\n    height: 160px;\n    align-self: flex-end;\n    object-fit: contain;\n\n    // iPhone XR\n    @media screen and (min-width: 414px) {\n        width: 190px;\n        height: 190px;\n        object-fit: contain;\n    }\n}\n\n.main {\n    height: auto;\n    max-width: calc(100% - 184px);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: flex-start;\n    object-fit: contain;\n\n\n    &.ltr {\n        padding: 30px 0 30px 30px;\n    }\n\n    &.rtl {\n        padding: 30px 30px 30px 0;\n    }\n\n    &_title {\n        max-width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__QDX5V`,
	"media": `styles_media__IuvfQ`,
	"main": `styles_main__0Rguz`,
	"ltr": `styles_ltr__jicg2`,
	"rtl": `styles_rtl__w8-yC`,
	"main_title": `styles_main_title__UMmbd`
};
export default ___CSS_LOADER_EXPORT___;
