// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__lFFMn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #171A1A;
}
.styles_container__lFFMn.styles_light__jLY0w {
  background: var(--widget);
  box-shadow: 0 1px 8px rgba(110, 110, 110, 0.1);
}
.styles_container__lFFMn.styles_dark__eVPfI {
  background: var(--border);
}

.styles_field__PlCu0 {
  max-width: 220px !important;
  height: 264px !important;
  margin-top: 30px !important;
}
@media screen and (min-width: 768px) {
  .styles_field__PlCu0 {
    margin-top: 10px;
  }
}

.styles_day__2mUr1 {
  color: orange;
}

h5, .styles_h5__th0Er {
  font-size: 0.75rem;
  font-weight: 600;
  max-width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/RefereeMatchCard/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;AAEI;EACI,yBAAA;EACA,8CAAA;AAAR;AAGI;EACI,yBAAA;AADR;;AAKA;EACI,2BAAA;EACA,wBAAA;EACA,2BAAA;AAFJ;AAKI;EANJ;IAOQ,gBAAA;EAFN;AACF;;AAKA;EACI,aAAA;AAFJ;;AASA;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;AANJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    border-radius: 4px;\n    margin-bottom: 20px;\n    background-color: #171A1A;\n\n\n    &.light {\n        background: var(--widget);\n        box-shadow: 0 1px 8px rgba(110, 110, 110, .1);\n    }\n\n    &.dark {\n        background: var(--border);\n    }\n}\n\n.field {\n    max-width: 220px !important;\n    height: 264px !important;\n    margin-top: 30px !important;\n\n    // tablet portrait\n    @media screen and (min-width: 768px) {\n        margin-top: 10px;\n    }\n}\n\n.day {\n    color: orange;\n}\n\n.active {\n\n}\n\nh5, .h5 {\n    font-size: 0.75rem;\n    font-weight: 600;\n    max-width: 80px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__lFFMn`,
	"light": `styles_light__jLY0w`,
	"dark": `styles_dark__eVPfI`,
	"field": `styles_field__PlCu0`,
	"day": `styles_day__2mUr1`,
	"h5": `styles_h5__th0Er`
};
export default ___CSS_LOADER_EXPORT___;
