// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_burger__WXFt8 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 18px;
}
.styles_burger_line__1JTNA {
  width: 20px;
  height: 2.4px;
  background: var(--highlight);
  border-radius: 4px;
  position: relative;
  right: 0;
  transition: right var(--transition);
}
.styles_burger__WXFt8.styles_active__TmdCP .styles_burger_line__1JTNA:nth-of-type(2) {
  right: -4px;
}`, "",{"version":3,"sources":["webpack://./src/ui/SidebarTrigger/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AACJ;AACI;EACI,WAAA;EACA,aAAA;EACA,4BAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,mCAAA;AACR;AAGQ;EACI,WAAA;AADZ","sourcesContent":[".burger {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 18px;\n\n    &_line {\n        width: 20px;\n        height: 2.4px;\n        background: var(--highlight);\n        border-radius: 4px;\n        position: relative;\n        right: 0;\n        transition: right var(--transition);\n    }\n\n    &.active {\n        .burger_line:nth-of-type(2) {\n            right: -4px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"burger": `styles_burger__WXFt8`,
	"burger_line": `styles_burger_line__1JTNA`,
	"active": `styles_active__TmdCP`
};
export default ___CSS_LOADER_EXPORT___;
