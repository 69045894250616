// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FianzenList.module.css */
.Kader_table__LKY-A {
    width: 100%;
    border-collapse: collapse;
}

.Kader_table__LKY-A th, .Kader_table__LKY-A td {
    padding: 20px;
    gap: 10px;
    text-align: left;
}

.Kader_header__KLOE3 {
    background-color: #171A1A;
    font-weight: bold;
    color: orange;
}

.Kader_row__BwqAZ:nth-child(even) {
    background-color: #24292B; /* Dark background for even rows */
    padding: 10px;
}

.Kader_row__BwqAZ:nth-child(odd) {
    background-color: #24292B; /* Slightly lighter for odd rows */
}

.Kader_cell__kkp9x {
    border-bottom: 1px solid #444; /* Subtle border between rows */
    color: white;
    font-size: 14px;
    font-weight: bold;
}
.Kader_infoText__N55nP {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    color: white;
}
.Kader_sub__gmdh9 {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    color: orange;
}
.Kader_lightH__yA8ij {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    color: orange;
}
.Kader_guthaben__TrOil {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    color: white;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Kader.module.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,yBAAyB,EAAE,kCAAkC;IAC7D,aAAa;AACjB;;AAEA;IACI,yBAAyB,EAAE,kCAAkC;AACjE;;AAEA;IACI,6BAA6B,EAAE,+BAA+B;IAC9D,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":["/* FianzenList.module.css */\n.table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.table th, .table td {\n    padding: 20px;\n    gap: 10px;\n    text-align: left;\n}\n\n.header {\n    background-color: #171A1A;\n    font-weight: bold;\n    color: orange;\n}\n\n.row:nth-child(even) {\n    background-color: #24292B; /* Dark background for even rows */\n    padding: 10px;\n}\n\n.row:nth-child(odd) {\n    background-color: #24292B; /* Slightly lighter for odd rows */\n}\n\n.cell {\n    border-bottom: 1px solid #444; /* Subtle border between rows */\n    color: white;\n    font-size: 14px;\n    font-weight: bold;\n}\n.infoText {\n    margin-bottom: 16px;\n    font-size: 18px;\n    line-height: 30px;\n    font-weight: bold;\n    color: white;\n}\n.sub {\n    margin-bottom: 16px;\n    font-size: 16px;\n    line-height: 30px;\n    font-weight: bold;\n    color: orange;\n}\n.lightH {\n    margin-bottom: 16px;\n    font-size: 16px;\n    line-height: 30px;\n    font-weight: bold;\n    color: orange;\n}\n.guthaben {\n    margin-bottom: 16px;\n    font-size: 20px;\n    line-height: 30px;\n    font-weight: bold;\n    color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Kader_table__LKY-A`,
	"header": `Kader_header__KLOE3`,
	"row": `Kader_row__BwqAZ`,
	"cell": `Kader_cell__kkp9x`,
	"infoText": `Kader_infoText__N55nP`,
	"sub": `Kader_sub__gmdh9`,
	"lightH": `Kader_lightH__yA8ij`,
	"guthaben": `Kader_guthaben__TrOil`
};
export default ___CSS_LOADER_EXPORT___;
