// views/HalfTimeView.js
import React from 'react';
import styles from './styles.module.scss'; // Ensure this file exists
const HalfTimeView = ({ match}) => {
    return (
        <div>
            {/*<h3>Half Time View</h3>*/}
        </div>
    );
};

export default HalfTimeView;
